import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest, deleteStudentRequest } from '../../store/approval/actions';
import { StudentInfo } from '../../store/approval/types';
import moment from 'moment';
import StudentFilter from '../../containers/filter/student-filter';
import UserTableSearch from './user-table-search';
import UserPendingList from './user-table-pending';
import pencilIcon from '../../images/icons/pencil.svg';
import userImage from '../../images/user/user-image.png';
import './user-table.css';

type DeliveryStatus = 'none' | 'physical' | 'ebook' | 'ignore';

interface Delivery {
    id: string; user_id: string; stage: string; status: string;
}



const UserTable = () => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [currentPage, setCurrentPage] = useState(1);

    const [expandedAddresses, setExpandedAddresses] = useState<Set<string>>(new Set());
    const [editingExpirationDateId, setEditingExpirationDateId] = useState<string | null>(null);
    const [expirationInputValues, setExpirationInputValues] = useState<{ [key: string]: string }>({});

    const [editingCourseId, setEditingCourseId] = useState<string | null>(null);
    const [courseInputValues, setCourseInputValues] = useState<{ [key: string]: string }>({});

    const emptyAddress = '—';
    const gradeMap: { [key: string]: string } = { 'E4': 'ป.4', 'E5': 'ป.5', 'E6': 'ป.6', 'J1': 'ม.1', 'J2': 'ม.2', 'J3': 'ม.3' };
    const { token } = loginState.data;

    const usersPerPage = 10;
    const currentUsers = approvedList
        .slice()
        .sort((a, b) => a.fullname.localeCompare(b.fullname))
        .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);
    const totalPages = Math.ceil(approvedList.length / usersPerPage);

    const [deliveries, setDeliveries] = useState<Delivery[]>([]);

    const maxStage = Math.max(...deliveries.map(delivery => parseInt(delivery.stage)));
    const stages = Array.from({ length: maxStage }, (_, i) => (i + 1).toString());

    const deliveryStatusMap: Record<DeliveryStatus, string> = {
        none: 'ยังไม่ส่ง', physical: 'ส่งหนังสือ', ebook: 'ส่ง E-Book', ignore: 'เพิกเฉย'
    };



    const formatCourse = (course: string) => course ? course : '—';

    const mapGrade = (grade: string): string => gradeMap[grade] || 'Unknown';

    const handleRejectClick = (data: StudentInfo) => dispatch(deleteStudentRequest(data));

    const renderPaginationButton = (pageNumber: number) => (
        <button
            key={pageNumber} onClick={() => setCurrentPage(pageNumber)}
            className={`user-pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
        >
            {pageNumber}
        </button>
    );

    const toggleAddress = (id: string) => {
        setExpandedAddresses(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const fetchDeliveries = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/deliveries`, token);
            setDeliveries(response.deliveries);
        } catch (err) {
            console.error('Error fetching deliveries:', err);
        }
    };

    const handleDeliveryStatus = async (userId: string, stage: string, status: string) => {
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/deliveries/upsert-statuses`, token, { userId, stage, status });
            await fetchDeliveries()
        } catch (err) {
            console.error('Error updating delivery status:', err);
        }
    };

    const handleExpirationDateClick = (userId: string, expireDate: string) => {
        setEditingExpirationDateId(userId);
        setExpirationInputValues(prev => ({ ...prev, [userId]: expireDate }));
    };

    const handleExpirationDateChange = (userId: string, value: string) => {
        setExpirationInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleExpirationDateBlur = async (userId: string) => {
        try {
            const newDate = expirationInputValues[userId];
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/update-expire-date`, token, { userId, newDate });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating expiration date:', error);
        } finally {
            setEditingExpirationDateId(null);
        }
    };

    const handleCourseClick = (userId: string, course: string) => {
        setEditingCourseId(userId);
        setCourseInputValues(prev => ({ ...prev, [userId]: course }));
    };

    const handleCourseChange = (userId: string, value: string) => {
        setCourseInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleCourseBlur = async (userId: string) => {
        try {
            const newCourse = courseInputValues[userId];
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/update-course`, token, { userId, newCourse });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating course:', error);
        } finally {
            setEditingCourseId(null);
        }
    };



    useEffect(() => {
        dispatch(fetchApprovedListRequest());
        fetchDeliveries();
    }, []);



    return (
        <div>
            <div className="filter-container">
                <StudentFilter />
                {approvedList.length > usersPerPage && (
                    <div className="user-pagination">
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                            className="user-pagination-button"
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map(renderPaginationButton)}
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}
                            className="user-pagination-button"
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>

            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-id">ID</th>
                        <th className="user-table-name">Name</th>
                        <th className="user-table-nickname">Nickname</th>
                        <th className="user-table-grade">Grade</th>
                        <th className="livestream-table-centered">Course</th>
                        <th className="user-table-expiration">Expiration Date</th>
                        {stages.map((stage) => (
                            <th key={stage} className={`user-table-stage`}>Stage {stage}</th>
                        ))}
                        <th className="user-table-address">Address</th>
                        <th className="user-table-add">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.length ? (
                        currentUsers.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const isExpanded = expandedAddresses.has(user.id);
                            const truncatedAddress = user.address ? user.address.length > 20 ? `${user.address.substring(0, 20)}...` : user.address : emptyAddress;
                            const formattedExpirationDate = user.expire_date ? moment(user.expire_date).format('DD MMM, YY') : emptyAddress;

                            const userDeliveries = deliveries.filter(delivery => delivery.user_id === user.id);
                            const userStageStatuses: Record<string, string> = {};

                            userDeliveries.forEach(delivery => {
                                userStageStatuses[delivery.stage] = delivery.status || 'none';
                            });

                            return (
                                <tr key={user.id}>
                                    <td className="user-table-id">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        <a href={`https://api.nockacademy.com/admin/profile/?id=${user.id}`} target="_blank" rel="noopener noreferrer">
                                            {user.id}
                                        </a>
                                    </td>
                                    <td>
                                        {user.fullname}
                                    </td>
                                    <td className={`user-table-nickname`}>{nickname}</td>
                                    <td className="user-table-grade">{mapGrade(user.grade)}</td>
                                    <td className="livestream-table-centered">
                                        {editingCourseId === user.id ? (
                                            <div className="user-course-container">
                                                <input
                                                    type="text" autoFocus maxLength={255}
                                                    value={courseInputValues[user.id] !== undefined ? courseInputValues[user.id] : user.course}
                                                    onChange={(e) => handleCourseChange(user.id, e.target.value)}
                                                    onBlur={() => handleCourseBlur(user.id)}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { handleCourseBlur(user.id) } }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="user-course-container" onClick={() => handleCourseClick(user.id, user.course)}>
                                                {formatCourse(user.course)}
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleCourseClick(user.id, user.course)} />
                                            </div>
                                        )}
                                    </td>
                                    <td className="user-table-expiration">
                                        {editingExpirationDateId === user.id ? (
                                            <input
                                                type="date"
                                                value={expirationInputValues[user.id] || formattedExpirationDate}
                                                onChange={(e) => handleExpirationDateChange(user.id, e.target.value)}
                                                onBlur={() => handleExpirationDateBlur(user.id)}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { handleExpirationDateBlur(user.id) } }}
                                            />
                                        ) : (
                                            <div className="user-table-expiration-container" onClick={() => handleExpirationDateClick(user.id, user.expire_date)}>
                                                <span className="user-table-expiration-date">{formattedExpirationDate}</span>
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleExpirationDateClick(user.id, user.expire_date)} />
                                            </div>
                                        )}
                                    </td>
                                    {stages.map((stage) => (
                                        <td key={stage} className={`user-table-stage`}>
                                            <select
                                                value={userStageStatuses[stage] || 'none'}
                                                onChange={(e) => handleDeliveryStatus(user.id, stage, e.target.value)}
                                            >
                                                {(Object.keys(deliveryStatusMap) as DeliveryStatus[]).map(key => (
                                                    <option key={key} value={key}>
                                                        {deliveryStatusMap[key]}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    ))}
                                    <td className="user-table-address">
                                        <span
                                            onClick={() => user.address && user.address !== emptyAddress ? toggleAddress(user.id) : null}
                                            style={{ cursor: user.address && user.address !== emptyAddress ? 'pointer' : 'default' }}
                                        >
                                            {isExpanded ? user.address : truncatedAddress}
                                        </span>
                                    </td>
                                    <td className="user-table-add">
                                        <button className="remove-button" onClick={() => handleRejectClick(user)}>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan={7} className="no-playlists">No users available</td></tr>
                    )}
                </tbody>
            </table>

            <UserPendingList />
            <UserTableSearch />
        </div>
    );
};

export default UserTable;
